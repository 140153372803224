export default {
  pageHeroV1: {
    container: {
      height: '70vh',
      width: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      alignItems: 'flex-end',
      justifyContent: 'center'
    },

    content: {
      flexDirection: 'column',
      color: 'white',
      textAlign: 'center',
      h1: {
        fontSize: ['1.75rem', '', '2.5rem'],
        marginBottom: '2rem',
        letterSpacing: '3px',
        textTransform: 'uppercase',
        color: 'white',
        borderTop: '7px solid',
        borderBottom: '7px solid',
        borderColor: 'primary',
        backgroundColor: 'rgba(255,255,255,0.35)',
        padding: '1rem'
        // writingMode: 'vertical-rl',
      },
      h2: {
        marginBottom: '2rem'
      },
      button: {
        variant: 'buttons.multiLocation'
      }
    }
  }
}
