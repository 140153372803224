export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.containerScrolled': {
      backgroundColor: 'secondary'
    },

    '.hamburger': {
      borderRadius: '100px',
      padding: ['0.8rem 0.4rem', '0.75rem'],
      backgroundColor: 'rgb(40 46 55 / 41%)',
      minWidth: '40px'
    },

    '.navMenuLogo': {
      img: {
        maxHeight: ['150px', '200px']
      }
    },

    '.navItem': {
      a: {
        color: 'white',
        ':hover': {
          color: 'grey'
        }
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'secondary',
      '.navItem': {
        a: {
          display: 'flex',
          padding: ['', '', '', '1rem'],
          transition: 'all ease-in-out 0.75s',
          ':hover': {
            transform: 'scale(1.1)'
          }
        }
      }
    },

    '.seperatorLine': {
      color: 'primary'
    },

    '.phoneSocialContainer': {
      a: {
        color: 'white',
        fontSize: '1rem'
      },
      svg: {
        width: '25px',
        height: '25px',
        path: {
          fill: 'white'
        }
      }
    }
  },

  footer: {
    backgroundColor: 'secondary',
    color: 'white',
    '.footerSectionHeading': {
      color: 'primary',
      borderBottom: '1px solid',
      borderColor: 'primary'
    },
    '.gonationLogo svg': {
      fill: 'white'
    },
    '.multiButtonContainer': {
      a: {
        ':hover': {
          opacity: '0.7',
          backgroundColor: 'transparent'
        }
      }
    }
  },

  ctaWidget: {
    a: {
      borderRadius: ['', '', '100px']
    }
  },
  loadingPage: {
    backgroundColor: 'secondary'
  },

  title: {
    borderLeft: 'none',
    paddingLeft: '0rem',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: ['2rem', '', '3rem'],
    fontFamily: 'body',
    marginBottom: '1rem',
    textAlign: 'left'
  },
  subtitle: {
    color: 'primary',
    fontSize: ['1.25rem', '1.5rem', '2rem'],
    fontWeight: '500',
    borderBottom: '1px solid',
    marginBottom: '1rem',
    textAlign: 'left'
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      lineHeight: ['1.25', '1.5', '1.75']
    },
    '.hero_content_container': {
      margin: ['1rem', '2rem', '', '3rem'],
      display: 'none'
    }
  },

  homepageHeroShout: {},

  homepageShout: {
    '.content': {
      // left: ['0rem', '', '2rem'],
      // width: ['100%', '70%'],
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.shoutCTA': {
      variant: 'buttons.primary'
    }
  },

  homepageAbout: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.section': {
      justifyContent: 'center'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageMenu: {
    backgroundColor: '#e7eef8',
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageGallery: {
    '.image': {
      height: ['250px', '', '350px'],
      objectFit: 'cover'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    }
  },

  homepageReservations: {
    justifyContent: 'center',
    alignItems: 'center',
    padding: '3rem 1rem',
    flexDirection: 'column',
    '.textContent': {
      marginBottom: '2rem',
      textAlign: 'center'
    },
    '.title': {
      fontSize: ['2rem', '', '2.5rem'],
      fontWeight: '400'
    }
  },

  homepageContactUs: {
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    color: 'white',
    padding: '10rem 1rem',
    '.section': {
      alignItems: 'flex-start',
      textAlign: 'left',
      margin: '0rem 0rem 0rem 5%',
      maxWidth: 'unset'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: '#d7cbcb'
    },
    '.text': {
      color: 'white',
      textAlign: 'left'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageInstagram: {
    '.logoAndTextContainer ': {
      // margin: '3rem 0rem 2rem',
      display: 'none'
    },
    '.iFrameContainer': {
      width: ['100%', '100%', '100%']
    }
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      variant: 'customVariants.title',
      textAlign: ['left', '', 'center']
    }
  },

  // ? ========================
  // ? =====  Menu page  ======
  // ? ========================

  menu: {
    '.dropdownContainer': {
      display: 'none'
    },

    '.backToMenuBtn': {
      variant: 'buttons.primary',
      marginBottom: '2rem'
    },

    '.menuContainer': {
      paddingBottom: '1rem'
    },

    // ===  cells ==
    '.cellImageContainer': {
      overflow: 'hidden',
      img: {
        transition: 'all ease-in-out 1s'
        // transformOrigin: 'center',
      },
      ':hover img': {
        transform: 'translate(-50%,-50%) scale(1.2)'
      }
    },
    '.menuCell, .cellImage': {
      borderRadius: '0px'
    },

    // ===  sections ==
    '.menuSectionTitle': {
      margin: '0rem 2rem 2rem',
      padding: '1rem',
      borderTop: '1px solid',
      borderBottom: '1px solid',
      borderColor: 'primary',
      color: 'primary',
      textTransform: 'uppercase'
    },

    // ===  items ==
    '.menuItemContentContainer': {
      margin: '0rem'
    },

    '.menuItemDescription': {
      opacity: '0.8'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {},

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.eventItemImage': {
      minHeight: 'unset'
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    backgroundColor: '#e7eef8',
    '.title': {
      variant: 'customVariants.title',
      textAlign: ['center', '', 'center']
    },
    '.subtitle': {
      fontSize: ['1rem', '', '', '1.25rem'],
      margin: '1rem 0rem',
      opacity: '0.8'
    },
    'form input, form textarea': {
      border: 'none',
      borderBottom: '1px solid grey',
      borderRadius: '0px'
    },
    'form .submitBtn': {
      variant: 'buttons.primary'
    }
  },

  locationMap: {
    order: '3',
    h3: {
      textTransform: 'uppercase',
      fontSize: ['1.25rem', '', '', '1.75rem']
    }
  }
}
